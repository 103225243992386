import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Avatar,
    Box,
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Paper,
    Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';

import AWS from 'aws-sdk';
import DownloadIcon from '@mui/icons-material/Download';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';
import config from '../config/config';

//const RDS_URL = config[process.env.REACT_APP_ENV].REACT_APP_RDS_API_URL;
//const redirect_base_url = config[process.env.REACT_APP_ENV].REACT_APP_MAIN_PAGE_URL_HEAD;
//const apk_url = config[process.env.REACT_APP_ENV].REACT_APP_S3_APK;

//const login_url = redirect_base_url+"/login"

// Configure AWS
AWS.config.update({
    region: 'ap-southeast-2',
    accessKeyId: config[process.env.REACT_APP_ENV].REACT_APP_accessKeyId,
    secretAccessKey: config[process.env.REACT_APP_ENV].REACT_APP_secretAccessKey,
});

const s3 = new AWS.S3();

const Installation = () => {
    const [fileUrls, setFileUrls] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedFileUrl, setSelectedFileUrl] = useState('');

    const redirect_base_url = config[process.env.REACT_APP_ENV].REACT_APP_MAIN_PAGE_URL_HEAD;
    //const apk_url = config[process.env.REACT_APP_ENV].REACT_APP_S3_APK;
    //const login_url = `${redirect_base_url}/login`;

    useEffect(() => {
        fetchFileUrls();
    }, []);

    const fetchFileUrls = async () => {
        try {
            const params = {
                Bucket: 'signinspire-marketing-documents',
                Prefix: 'apk/prod/', // Ensure it ends with a slash if it's a folder
            };

            //console.log('params',params)

            const data = await s3.listObjectsV2(params).promise();

            //console.log('data',data)
            const files = data.Contents.filter((file) => file.Key && file.Key !== 'apk/prod/') // Exclude empty or placeholder keys
                .map((file) => ({
                    name: file.Key.split('/').pop(),
                    url: s3.getSignedUrl('getObject', {
                        Bucket: params.Bucket,
                        Key: file.Key,
                        Expires: 60 * 5, // URL expiry time in seconds
                    }),
                }));

            //console.log('files',files)

            setFileUrls(files);
        } catch (err) {
            console.error('Error fetching files from S3:', err);
        }
    };

    const handleDownloadClick = (fileUrl, fileName) => {
        // Create an invisible <a> element
        const link = document.createElement('a');

        //console.log('fileUrl',fileUrl)
        link.href = fileUrl;
        link.download = fileName; // Suggest a filename for the download

        // Append the <a> element to the body (it needs to be in the DOM for the click to work)
        document.body.appendChild(link);

        // Programmatically click the <a> element to trigger the download
        link.click();

        // Remove the <a> element from the DOM after the download is triggered
        document.body.removeChild(link);
    };

    return (
        <div>
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="30vh" padding={4}>
                <Container>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item xs={12} md={6}>
                            <Typography variant="h4" component="h2" gutterBottom>
                                Easy Installation Process
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="body1" paragraph>
                                Experience hassle-free installation with our AI-enhanced digital signage app.
                                Simply follow the step-by-step instructions and start showcasing your content
                                in no time.
                            </Typography>
                            <Box>
                                <Button
                                    variant="contained"
                                    href="/login"
                                    color="primary"
                                    style={{
                                        backgroundColor: '#0444AB',
                                        color: '#ffffff',
                                        marginRight: '10px',
                                    }}
                                >
                                    Get Started
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="30vh" padding={4}>
                <Container>
                    <Box mt={4}>
                        <Typography variant="overline" display="block" gutterBottom>
                            Smart Device
                        </Typography>
                        <Typography variant="h4" component="h2" gutterBottom>
                            Get our app on the Google Play Store today
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Follow these simple instructions to download and install our app from the Google
                            Play Store.
                        </Typography>
                        <Grid container spacing={4} alignItems="center">
                            <Grid item xs={12} md={6}>
                                <Grid container spacing={4}>
                                    <Grid item xs={12} sm={6}>
                                        <Box display="flex" mb={2}>
                                            <Box mr={2}>
                                                <img
                                                    src="https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/MainPage/icon_only.png"
                                                    alt="Step One Icon"
                                                    style={{ width: '100px', height: '50px' }}
                                                />
                                            </Box>
                                            <Box>
                                                <Typography variant="h6" gutterBottom>
                                                    Step One
                                                </Typography>
                                                <Typography variant="body2">
                                                    Open the Google Play Store on your Android device.
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Box display="flex" mb={2}>
                                            <Box mr={2}>
                                                <img
                                                    src="https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/MainPage/icon_only.png"
                                                    alt="Step Two Icon"
                                                    style={{ width: '100px', height: '50px' }}
                                                />
                                            </Box>
                                            <Box>
                                                <Typography variant="h6" gutterBottom>
                                                    Step Two
                                                </Typography>
                                                <Typography variant="body2">
                                                    Search for our app "SignInspire" using the search bar.
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Box mt={2}>
                                    <Button
                                        variant="contained"
                                        href="https://play.google.com/store/apps/details?id=com.signinspire.au.pro&gl=au"
                                        color="primary"
                                        style={{
                                            backgroundColor: '#0444AB',
                                            color: '#ffffff',
                                            marginRight: '10px',
                                        }}
                                    >
                                        Go Play Store
                                    </Button>
                                </Box>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={6}
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Box display="flex" flexDirection="column" alignItems="center">
                                    <img
                                        src="https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/MainPage/Get_It_From_Google_Play_Store.png"
                                        alt="Google Play"
                                        style={{ width: '200px', height: 'auto', marginBottom: '20px' }}
                                    />
                                    <IconButton
                                        target="_blank"
                                        rel="noopener"
                                        style={{ marginRight: '10px' }}
                                    >
                                        <img
                                            src="https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/MainPage/icon_app.png"
                                            alt="Sign Inspire Logo"
                                            style={{ width: '150px', height: 'auto' }}
                                        />
                                    </IconButton>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </Box>

            <Box display="flex" justifyContent="center" alignItems="center" minHeight="30vh" padding={4}>
                <Container>
                    <Box mt={4}>
                        <Typography variant="overline" display="block" gutterBottom>
                            Non Smart Device
                        </Typography>
                        <Typography variant="h4" component="h2" gutterBottom>
                            Easy Installation Process for Our App via APK File
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Installing our app on your Android device is quick and hassle-free. Follow these
                            simple steps:
                        </Typography>
                        <Grid container spacing={4} alignItems="center">
                            <Grid item xs={12} md={6}>
                                <Grid container spacing={4}>
                                    <Grid item xs={12} sm={6}>
                                        <Box display="flex" mb={2}>
                                            <Box mr={2}>
                                                <img
                                                    src="https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/MainPage/icon_only.png"
                                                    alt="Step One Icon"
                                                    style={{ width: '100px', height: '50px' }}
                                                />
                                            </Box>
                                            <Box>
                                                <Typography variant="h6" gutterBottom>
                                                    Step One
                                                </Typography>
                                                <Typography variant="body2">
                                                    Download the APK file from our website or trusted source.
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Box display="flex" mb={2}>
                                            <Box mr={2}>
                                                <img
                                                    src="https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/MainPage/icon_only.png"
                                                    alt="Step Two Icon"
                                                    style={{ width: '100px', height: '50px' }}
                                                />
                                            </Box>
                                            <Box>
                                                <Typography variant="h6" gutterBottom>
                                                    Step Two
                                                </Typography>
                                                <Typography variant="body2">
                                                    Enable 'Unknown Sources' in your device's security
                                                    settings.
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Box mt={2}>
                                    <Button
                                        variant="contained"
                                        onClick={() => setDialogOpen(true)}
                                        color="primary"
                                        style={{
                                            backgroundColor: '#0444AB',
                                            color: '#ffffff',
                                            marginRight: '10px',
                                        }}
                                    >
                                        Download APK
                                    </Button>
                                </Box>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={6}
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Box display="flex" flexDirection="column" alignItems="center">
                                    <IconButton
                                        target="_blank"
                                        rel="noopener"
                                        style={{ marginRight: '10px' }}
                                    >
                                        <img
                                            src="https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/MainPage/apk.png"
                                            alt="Sign Inspire Logo"
                                            style={{ width: '150px', height: 'auto' }}
                                        />
                                    </IconButton>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </Box>

            <Box display="flex" justifyContent="center" alignItems="center" minHeight="70vh" padding={4}>
                <Container>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} md={6}>
                            <img
                                src={
                                    'https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/MainPage/B3.jpg'
                                }
                                alt="AI"
                                style={{
                                    width: '100%',
                                    height: 'auto',
                                    objectFit: 'cover',
                                    minHeight: '50vh',
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Box display="flex" flexDirection="column" alignItems="center">
                                <Typography variant="h4" component="h1" gutterBottom>
                                    Universal Android Compatibility: From 5.0 to the Latest Versions
                                </Typography>
                                <Typography variant="body1" paragraph>
                                    Our digital signage app is fully compatible with Android devices from
                                    version 5.0 to the latest, allowing you to leverage the latest features
                                    and capabilities.
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Box display="flex" justifyContent="center" alignItems="center" minHeight="30vh" padding={4}>
                <Container maxWidth="md" style={{ marginTop: '2rem', marginBottom: '2rem' }}>
                    <Typography variant="h4" component="h2" gutterBottom align="center">
                        Frequently Asked Questions
                    </Typography>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h6">How to install the app?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body1">
                                <strong>Via Google Play Store:</strong> Open the Google Play Store app on your
                                Android device. Search for "SignInspire" using the search bar. Once you find
                                the app, tap on it and then tap "Install".
                                <br />
                                <br />
                                <strong>Via APK File:</strong> Download the APK file from our website or a
                                trusted source. Go to your device's settings and enable "Unknown Sources" in
                                the security settings. Locate the downloaded APK file using a file manager
                                app, tap on it, and follow the on-screen instructions to install.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h6">How to troubleshoot issues?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body1">
                                <strong>Clear Cache:</strong> Go to Settings &gt; Apps &gt; [Your App] &gt;
                                Storage &gt; Clear Cache.
                                <br />
                                <br />
                                <strong>Update the App:</strong> Ensure you have the latest version of the app
                                installed. Go to the Google Play Store and check for updates.
                                <br />
                                <br />
                                <strong>Reinstall the App:</strong> Uninstall the app and then reinstall it
                                from the Google Play Store or using the APK file.
                                <br />
                                <br />
                                <strong>Restart Your Device:</strong> Sometimes, simply restarting your device
                                can resolve issues.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h6">Can I use the app on multiple devices?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body1">
                                Yes, you can use the app on multiple devices. Install the app on each device
                                from the Google Play Store or using the APK file. Ensure you are logged in
                                with the same account on all devices to sync your data and preferences.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h6">How to update the app?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body1">
                                <strong>Via Google Play Store:</strong> Open the Google Play Store app on your
                                Android device. Tap on the menu icon (three horizontal lines) and go to "My
                                apps & games". Find "SignInspire" in the list of installed apps and tap
                                "Update" if an update is available.
                                <br />
                                <br />
                                <strong>Via APK File:</strong> Download the latest version of the APK file
                                from our website or a trusted source. Follow the same steps as installing via
                                APK to install the updated version.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h6">How to uninstall the app?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body1">
                                <strong>Via Google Play Store:</strong> Open the Google Play Store app on your
                                Android device. Search for "SignInspire" and tap on it. Tap "Uninstall" and
                                confirm the uninstallation.
                                <br />
                                <br />
                                <strong>Via Device Settings:</strong> Go to Settings &gt; Apps &gt; [Your
                                App]. Tap "Uninstall" and confirm the uninstallation.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Container>
            </Box>

            <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                minHeight="30vh"
                padding={4}
            >
                <Box mb={2}>
                    <Typography variant="h4" component="h2" gutterBottom align="center">
                        Still have questions?
                    </Typography>
                </Box>
                <Box mb={2}>
                    <Typography variant="h6" align="center">
                        Contact our support team for further assistance.
                    </Typography>
                </Box>
                <Box>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ backgroundColor: '#0444AB', color: '#ffffff' }}
                    >
                        Contact Us
                    </Button>
                </Box>
            </Box>

            <Dialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                fullWidth
                maxWidth="sm"
                aria-labelledby="dialog-title"
                aria-describedby="dialog-description"
            >
                <DialogTitle id="dialog-title">Select APK File</DialogTitle>
                <DialogContent>
                    {fileUrls.length > 0 ? (
                        <List>
                            {fileUrls.map((file, index) => (
                                <ListItem
                                    key={index}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        mb: 1,
                                    }}
                                >
                                    <ListItemText primary={file.name} />
                                    <IconButton
                                        color="secondary"
                                        onClick={() => handleDownloadClick(file.url, file.name)}
                                        aria-label={`Download ${file.name}`}
                                    >
                                        <DownloadIcon />
                                    </IconButton>
                                </ListItem>
                            ))}
                        </List>
                    ) : (
                        <Typography variant="body1">No files available for download.</Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialogOpen(false)} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default Installation;
