import {
    AppBar,
    Box,
    Button,
    Container,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Toolbar,
    Typography,
} from '@mui/material';
import { Link, Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import MenuIcon from '@mui/icons-material/Menu';
import NavButton from './NavButton';
import React from 'react';

const WebHeader = () => {
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const drawerWidth = 240;
    const navItems = [
        { label: 'Home', path: '/' },
        { label: 'Installation', path: '/installation' },
        { label: 'Pricing', path: '/pricing' },
        { label: 'Contact Us', path: '/contact' },
        { label: 'Attention Test', path: '/ad-attention-test' },
    ];
    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center', zIndex: '9000' }}>
            <Typography variant="h6" sx={{ my: 2 }}>
                Sign Inspire
            </Typography>
            <Divider />
            <List>
                {navItems.map((item) => (
                    <ListItem key={item.label} disablePadding>
                        <ListItemButton
                            component={Link} // Use Link component
                            to={item.path} // Pass the path
                            sx={{ textAlign: 'center' }}
                        >
                            <ListItemText primary={item.label} />
                        </ListItemButton>
                    </ListItem>
                ))}
                <Divider />

                <ListItem
                    sx={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: '10px' }}
                >
                    <Button
                        sx={{ borderColor: '#0444AB', color: '#0444AB', alignSelf: 'center', width: '100px' }}
                        variant="outlined"
                        component={Link}
                        to="/login"
                    >
                        Log In
                    </Button>
                </ListItem>
                <ListItem sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <Button
                        sx={{ alignSelf: 'center', width: '100px', backgroundColor: '#0444AB' }}
                        variant="contained"
                        color="primary"
                        component={Link}
                        to="/signup"
                    >
                        Free Trial
                    </Button>
                </ListItem>
            </List>
        </Box>
    );
    return (
        <div>
            <AppBar position="static" component="nav" style={{ backgroundColor: 'white', color: 'black' }}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        variant="h6"
                        component="div"
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            fontWeight: 'bold',
                            fontSize: '1.25rem', // Adjust size as needed
                            color: '#0444AB', // Use your primary color
                        }}
                    >
                        <img
                            src="https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/MainPage/icon_app.png" // Your image URL
                            alt="App Icon"
                            style={{
                                width: '50px', // Adjust size as needed
                                height: '50px', // Adjust size as needed
                                marginRight: '10px', // Space between icon and text
                            }}
                        />
                        Sign Inspire
                    </Typography>
                    <Box sx={{ display: { xs: 'none', sm: 'flex' }, justifyContent: 'center', flexGrow: 1 }}>
                        {navItems.map((item) => (
                            <NavButton
                                key={item.label}
                                sx={{ color: 'black' }}
                                // component={Link}
                                title={item.label}
                                color="black"
                                page={item.path}
                            />
                        ))}
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexGrow: 0,
                            justifyContent: { xs: 'flex-end', sm: 'flex-end' },
                        }}
                    >
                        <Button
                            sx={{
                                display: { xs: 'none', sm: 'flex' },
                                borderColor: '#0444AB',
                                color: '#0444AB',
                                width: '100px',
                            }}
                            variant="outlined"
                            component={Link}
                            to="/login"
                        >
                            Log In
                        </Button>
                        <Button
                            sx={{ flexGrow: 0, display: { xs: 'none', sm: 'flex' } }}
                            variant="contained"
                            color="primary"
                            component={Link}
                            to="/signup"
                            style={{ backgroundColor: '#0444AB', marginLeft: '10px', width: '100px' }}
                        >
                            Free Trial
                        </Button>
                    </Box>
                </Toolbar>
            </AppBar>
            <nav>
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
            </nav>
        </div>
    );
};

export default WebHeader;
