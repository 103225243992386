import { Autocomplete, Button, TextField } from '@mui/material';
import { GetMenuList, GetUserInfo } from '../../api/api';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
// export default Sidebar;
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { AiOutlineMenu } from 'react-icons/ai';
import { MdOutlineCloudQueue } from 'react-icons/md';
import { UserMenu } from './utils';
// import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { useStateContext } from '../../contexts/ContextProvider';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#0444AB' : '#308fe8',
    },
}));

const SidebarContext = createContext();

export default function Sidebar({ children }) {
    //console.log('Sidebar => children:', children);
    const { currentColor, activeMenu, setActiveMenu, screenSize, currentUser } = useStateContext();
    const navigate = useNavigate();
    // const [expanded, setExpanded] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleDropdownMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    // const activeMenu = true;
    const switchedUser = JSON.parse(localStorage.getItem('switchUser'));

    const [userList, setUserList] = useState([]);
    const [switchUser, setSwitchUser] = useState(switchedUser);
    const [menuList, setMenuList] = useState([]);
    // const [userInfo, setUserInfo] = useState();
    const userInfo = JSON.parse(localStorage.getItem('userDetails'));
    const handleMenuList = async () => {
        const { code, data, msg } = await GetMenuList(switchUser);
        if (code === 0) {
            setMenuList(data?.list);
        }
        // else {
        //     alert(msg);
        // }
    };
    useEffect(() => {
        // handleGetStorage();
        handleMenuList();
    }, []);

    useEffect(() => {
        setUserList(JSON.parse(localStorage.getItem('allUsers')));
    }, [localStorage.getItem('allUsers')]);

    const [value, setValue] = React.useState();
    //  //console.log('file: Sidebar.jsx:126 => Sidebar => value:', value);
    const formattedUserList = userList?.map((user) => ({
        label: user.username,
        value: user.user_id,
    }));
    //  //console.log('file: Sidebar.jsx:132 => formattedUserList => formattedUserList:', formattedUserList);
    const handleSelectUser = (newValue) => {
        //  //console.log('file: Sidebar.jsx:133 => handleSelectUser => newValue:', newValue);
        currentUser.user_id !== newValue.value
            ? localStorage.setItem('switchUser', JSON.stringify(newValue.value))
            : localStorage.removeItem('switchUser');
        const name = userList.find((x) => x.user_id === newValue.value).username;
        // handleGetStorage(switchedUser ? switchedUser : null);
        //  //console.log('file: Sidebar.jsx:88 ===> handleChange ===> name:', name);
        localStorage.setItem('switchUserName', JSON.stringify(name));
        window.location.href = './dashboard/schedule';
        // handleGetStorage();
    };

    const handleRedirectToHome = () => {
        setActiveMenu(false);
        window.location.href = './';
    };
    // const handleGetStorage = async () => {
    //     //  //console.log('file: Sidebar.jsx:132 => handleGetStorage => user_id:', switchedUser);
    //     const { code, data } = await GetUserInfo(currentUser.user_id);
    //     if (code === 0) {
    //         setUserInfo(data);
    //         localStorage.setItem('userDetails', JSON.stringify(data));
    //     }
    // };

    return (
        <>
            <div className={`h-screen`}>
                <nav className="h-full flex flex-col bg-white shadow-sm">
                    <div className="p-4 pb-2 flex justify-between items-center">
                        <img
                            src={
                                'https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/MainPage/logo_signinspire_wide.jpg'
                            }
                            alt="Logo"
                            className={`overflow-hidden transition-all cursor-pointer ${
                                activeMenu ? 'w-32' : 'w-0'
                            }`}
                            onClick={handleRedirectToHome}
                        />

                        <button
                            onClick={() => setActiveMenu((curr) => !curr)}
                            className="p-1.5 rounded-lg bg-gray-50 hover:bg-gray-100"
                        >
                            <AiOutlineMenu />
                        </button>
                    </div>

                    <SidebarContext.Provider value={{ activeMenu }}>
                        <ul className="flex-1 px-3">{children}</ul>
                    </SidebarContext.Provider>
                    {/* <div className={`flex-grow bg-blue-300`} /> */}
                    <div
                        className={`p-4 mb-3 flex flex-col  overflow-hidden transition-all ${
                            activeMenu
                                ? 'ml-3 bg-gradient-to-tr from-blue-200 to-blue-100 mx-3 rounded-lg'
                                : 'w-0'
                        }`}
                    >
                        <div className="w-full flex flex-col">
                            <div className="flex justify-start align-middle ml-2 mb-3">
                                <MdOutlineCloudQueue style={{ fontSize: '22px' }} />

                                <span
                                    className={`mb-0 font-semibold overflow-hidden transition-all ${
                                        activeMenu ? 'w-52 ml-3' : 'w-0'
                                    }`}
                                >
                                    Storage
                                </span>
                            </div>
                            <span
                                className={`text-gray-600 overflow-hidden transition-all capitalize ${
                                    activeMenu ? 'w-52 ml-3' : 'w-0'
                                }`}
                            >
                                {(currentUser.user_level === 0 && switchUser === null) ||
                                userInfo?.customer_type === 2
                                    ? 'unlimited'
                                    : `${
                                          userInfo?.usable_storage_space_total
                                              ? (
                                                    1 -
                                                    userInfo?.usable_storage_space_total / 1073741824
                                                ).toFixed(2)
                                              : (0).toFixed(2)
                                      } GB of 1 GB used`}
                            </span>
                        </div>
                        <div className="w-full mt-1.5">
                            <BorderLinearProgress
                                variant="determinate"
                                className={`  overflow-hidden transition-all ${
                                    activeMenu ? 'w-52 ml-3' : 'w-0'
                                }`}
                                value={
                                    currentUser.user_level === 0 || userInfo?.customer_type === 2
                                        ? userInfo?.usable_storage_space_total === 0
                                            ? 100
                                            : parseInt(
                                                  (
                                                      ((1073741824 - userInfo?.usable_storage_space_total) /
                                                          1073741824) *
                                                      100
                                                  )?.toFixed(0),
                                              )
                                        : 0
                                }
                            />
                        </div>
                        {userInfo?.customer_type === 1 && (
                            <div
                                className={`flex mt-3 justify-center overflow-hidden transition-all ${
                                    activeMenu ? 'w-52 ml-3' : 'w-0'
                                }`}
                            >
                                <Button variant="contained" onClick={() => navigate('./dashboard/payment')}>
                                    Upgrade now
                                </Button>
                            </div>
                        )}
                    </div>

                    <div className="border-t flex p-3 cursor-pointer flex-row" onClick={handleDropdownMenu}>
                        <div
                            // src="https://ui-avatars.com/api/?background=c7d2fe&color=3730a3&bold=true"
                            // alt=""
                            className="w-10 h-10 rounded-md bg-[#c7d2fe] text-[#3730a3] flex justify-center items-center uppercase"
                        >
                            {userInfo?.username[0]}
                        </div>
                        <div
                            className={`
              flex justify-between items-center
              overflow-hidden transition-all ${activeMenu ? 'w-52 ml-3' : 'w-0'}
          `}
                        >
                            <div className="leading-4">
                                <h6 className="font-semibold capitalize">{userInfo?.username}</h6>
                                <span className="text-xs text-gray-600">
                                    {userInfo?.customer_type === 2
                                        ? 'Inspirer'
                                        : userInfo?.customer_type === 1
                                        ? 'Creator'
                                        : 'Designer'}
                                </span>
                            </div>

                            {/* <MoreVertical size={20} /> */}
                        </div>
                    </div>
                    <div className="border-t flex p-1 cursor-pointer flex-row">
                        {currentUser.user_level === 0 && (
                            <div
                                className={`
                flex justify-between transition-all  ${activeMenu ? 'w-full m-3' : 'w-0 h-0 overflow-hidden '}
                          `}
                                // onClick={(e) => e.stopPropagation()}
                            >
                                <Autocomplete
                                    fullWidth
                                    disablePortal
                                    disableClearable
                                    id="combo-box-demo"
                                    options={formattedUserList}
                                    value={
                                        switchUser
                                            ? {
                                                  label: userList.find((x) => x.user_id === switchUser)
                                                      ?.username,
                                                  value: userList.find((x) => x.user_id === switchUser)
                                                      ?.user_id,
                                              }
                                            : {
                                                  label: currentUser?.username,
                                                  value: currentUser?.user_id,
                                              }
                                    }
                                    isOptionEqualToValue={(option, value) => option.value === value.value}
                                    onChange={(e, newValue) => handleSelectUser(newValue)}
                                    // sx={{ width: '300px' }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </div>
                        )}
                    </div>
                </nav>
                <UserMenu anchorEl={anchorEl} open={open} handleClose={handleClose} />
            </div>
        </>
    );
}

export function SidebarItem({ icon, text, alert, path }) {
    //console.log('SidebarItem => text:', text);
    const { activeMenu } = useContext(SidebarContext);
    const navigate = useNavigate();
    const { pathname } = useLocation();
    //console.log('SidebarItem => pathname:', pathname);
    // const isActive = pathname.includes('home')
    //     ? text.toLowerCase() === 'dashboard'
    //     : pathname.split('/').pop().includes('-')
    //     ? pathname.split('/').pop().replace('-', ' ') === text.toLowerCase()
    //     : pathname.split('/').pop() === text.toLowerCase();
    const pathSegments = pathname.split('/');
    //console.log('SidebarItem => pathSegments:', pathSegments);
    //console.log('SidebarItem => pathSegments:', pathSegments.length);

    //console.log('SidebarItem => pathSegments[2]:', pathSegments[2]);
    const isActive = pathname.includes('home')
        ? text.toLowerCase() === 'dashboard'
        : pathSegments[2]?.replace('-', ' ') === text.toLowerCase(); // 判断第二个元素
    // : // : pathname.includes('home')
    //   text.toLowerCase() === 'dashboard';
    // : pathSegments.pop().includes('-')
    // ? pathSegments.pop().replace('-', ' ') === text.toLowerCase()
    // : pathSegments.pop() === text.toLowerCase();

    return (
        <li
            className={`
                relative flex items-center py-2 px-3 my-1
                font-large rounded-md cursor-pointer
                transition-colors group/menu h-[64px]
                ${isActive ? 'bg-gradient-to-tr bg-[#0444AB] text-white' : 'hover:bg-blue-50 text-black-400'}
          `}
            // className={({ isActive }) => (isActive ? activeLink : normalLink)}
            onClick={() => navigate(path)}
        >
            {/* <div className="flex items-center"> */}
            {icon}
            <span
                className={`overflow-hidden transition-all capitalize ${
                    activeMenu ? 'w-52 ml-3' : 'w-0 invisible'
                }`}
            >
                {text}
            </span>
            {/* </div> */}
            {alert && (
                <div
                    className={`absolute right-2 w-2 h-2 rounded bg-indigo-400 ${activeMenu ? '' : 'top-2'}`}
                />
            )}

            {!activeMenu && (
                <div
                    className={`
          absolute left-full rounded-md px-2 py-1 ml-6
          bg-indigo-100 text-indigo-800 text-sm
          invisible opacity-20 -translate-x-3 transition-all capitalize
          group-hover/menu:visible group-hover/menu:opacity-100 group-hover/menu:translate-x-0
      `}
                >
                    {text}
                </div>
            )}
        </li>
    );
}
