import {
    Box,
    Button,
    Container,
    FormControlLabel,
    Grid,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import React, { useState } from 'react';

import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp';
import Chip from '@mui/material/Chip';
import config from '../config/config';

//const RDS_URL = config[process.env.REACT_APP_ENV].REACT_APP_RDS_API_URL;
//const redirect_base_url = config[process.env.REACT_APP_ENV].REACT_APP_MAIN_PAGE_URL_HEAD;

//const login_url = redirect_base_url+"/login"

const PricingPlans = () => {
    const [billingCycle, setBillingCycle] = useState('yearly');

    const handleBillingCycleChange = (event) => {
        setBillingCycle(event.target.checked ? 'yearly' : 'monthly');
    };

    const plans = [
        {
            name: 'Creator',
            monthlyPrice: '$14.99/mo',
            yearlyPrice: '$144/yr',
            description: 'Discover the fundamentals of digital signage with our introductory lite plan.',
        },
        {
            name: 'Inspirer',
            monthlyPrice: '$19.99/mo',
            yearlyPrice: '$192/yr',
            description: 'Unleash your creativity with AI-driven content creation and intuitive controls.',
        },
        {
            name: 'Designer',
            monthlyPrice: '$29.99/mo',
            yearlyPrice: '$288/yr',
            description: 'Elevate your content with enterprise-level design and lock-in lifetime pricing.',
        },
    ];

    const calculatePrice = (monthlyPrice, yearlyPrice) => {
        if (billingCycle === 'yearly') {
            const monthlyEquivalent = parseFloat(yearlyPrice.replace('$', '').replace('/yr', '')) / 12;
            const discountedPrice = (monthlyEquivalent - 0.01).toFixed(2);
            return `$${discountedPrice}/mo`;
        }
        return monthlyPrice;
    };

    const Mainfeatures = [
        {
            feature: 'Price',
            creator: calculatePrice('$14.99/mo', '$144/yr'),
            inspirer: calculatePrice('$19.99/mo', '$192/yr'),
            designer: calculatePrice('$29.99/mo', '$288/yr'),
        },
        { feature: 'Number of screens', creator: '1', inspirer: '1', designer: 'Minimum 10' },
        { feature: 'Cloud Media Storage', creator: '1GB', inspirer: 'Unlimited', designer: 'Unlimited' },
        { feature: 'Device Auto Start ', creator: true, inspirer: true, designer: true },
        { feature: 'Digital Signage Essentials', creator: true, inspirer: true, designer: true },
        { feature: 'PDF Document Upload', creator: true, inspirer: true, designer: true },
        { feature: 'Display Web Page', creator: false, inspirer: true, designer: true },
        { feature: 'Advance Touch Function', creator: false, inspirer: true, designer: true },
        { feature: 'Location Management', creator: true, inspirer: true, designer: true },
        { feature: 'Pre-defined Template', creator: true, inspirer: true, designer: true },
        { feature: 'Playlist', creator: true, inspirer: true, designer: true },
        { feature: 'Schedule Contents', creator: true, inspirer: true, designer: true },
    ];

    const AccountFeatures = [
        { feature: 'Offline Notification Tracking', creator: true, inspirer: true, designer: true },
        { feature: 'Offline Email Notification ', creator: true, inspirer: true, designer: true },
        { feature: '2-Factor SMS Authentication', creator: true, inspirer: true, designer: true },
        { feature: 'Analytics Report', creator: false, inspirer: true, designer: true },
    ];

    const AIFeatures = [
        { feature: 'Content Moderation', creator: true, inspirer: true, designer: true },
        { feature: 'EveryDay Inspiration', creator: true, inspirer: true, designer: true },
        { feature: '4K Image Upscaling', creator: false, inspirer: true, designer: true },
        { feature: 'AI Image Generation', creator: false, inspirer: true, designer: true },
        { feature: 'Text Enhancement', creator: false, inspirer: true, designer: true },
        { feature: 'AI Image Restyling', creator: false, inspirer: true, designer: true },
        { feature: 'Image Resizing & Segmentation', creator: false, inspirer: true, designer: true },
        { feature: 'Image Animation', creator: false, inspirer: true, designer: true },
        { feature: 'Batch Mode', creator: false, inspirer: false, designer: true },
    ];

    const OtherFeatures = [
        { feature: '5/24 Support', creator: true, inspirer: true, designer: true },
        { feature: '7/24 Support', creator: false, inspirer: true, designer: true },
        {
            feature: 'Content Design & Development',
            creator: false,
            inspirer: false,
            designer: 'First 12 hours free',
        },
        { feature: 'Customised Project ', creator: false, inspirer: false, designer: 'First 12 hours free' },
        { feature: 'API access and integration', creator: false, inspirer: false, designer: true },
        { feature: 'Multi-Screen Discount ', creator: false, inspirer: false, designer: true },
        { feature: 'Lifetime Capped Rate', creator: false, inspirer: false, designer: true },
    ];

    return (
        <Container maxWidth="lg" style={{ marginTop: '2rem', marginBottom: '2rem' }}>
            <Typography variant="h4" component="h1" gutterBottom align="center">
                Flexible Pricing Options
            </Typography>
            <Typography variant="body1" paragraph align="center">
                Choose the plan that suits your needs and budget. Our pricing plans offer a range of features
                to help you create, inspire, and design with ease.
            </Typography>

            <Box my={4} textAlign="center">
                <Typography variant="h5" component="h2" gutterBottom>
                    Compare
                </Typography>
                <Typography variant="h6" component="h3" gutterBottom>
                    Pricing Plans
                </Typography>
                <Typography variant="body2" paragraph>
                    Choose the plan that suits your needs
                </Typography>
                <Box display="flex" justifyContent="center" alignItems="center" my={2}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={billingCycle === 'yearly'}
                                onChange={handleBillingCycleChange}
                                name="billingCycle"
                            />
                        }
                        label={billingCycle === 'monthly' ? 'Monthly' : 'Yearly'}
                    />
                </Box>
            </Box>

            <Box display="flex" justifyContent="flex-end">
                <Grid container spacing={4} justifyContent="flex-end">
                    {plans.map((plan, index) => (
                        <Grid item xs={12} md={3} key={index}>
                            <Box border={1} borderRadius={4} p={2} textAlign="center">
                                <Typography variant="h6" component="h3" gutterBottom>
                                    {plan.name}
                                </Typography>
                                <Box display="flex" justifyContent="center" alignItems="baseline">
                                    <Typography
                                        variant="body2"
                                        component="span"
                                        style={{ marginRight: '4px' }}
                                    >
                                        From
                                    </Typography>
                                    <Typography variant="h3" component="p" gutterBottom>
                                        {billingCycle === 'monthly'
                                            ? plan.monthlyPrice.split('/')[0]
                                            : plan.yearlyPrice.split('/')[0]}
                                    </Typography>
                                </Box>
                                {billingCycle === 'yearly' && (
                                    <Box display="flex" justifyContent="center" marginTop="8px">
                                        <Chip
                                            label="20% Off"
                                            color="secondary"
                                            size="small"
                                            style={{ fontWeight: 'bold', fontSize: '0.875rem' }}
                                        />
                                    </Box>
                                )}
                                <Typography variant="body2" component="p">
                                    /{billingCycle === 'monthly' ? 'mo/screen' : 'yr/screen'}
                                </Typography>
                                <Typography variant="body2" paragraph>
                                    {plan.description}
                                </Typography>
                                <Button
                                    href="/login"
                                    variant="contained"
                                    color="primary"
                                    style={{ backgroundColor: '#0444AB', color: '#ffffff' }}
                                >
                                    Get started
                                </Button>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Box>

            <Box mt={4}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ width: '25%', fontSize: '1.25rem', fontWeight: 'bold' }}>
                                Main Features
                            </TableCell>
                            {plans.map((plan, index) => (
                                <TableCell
                                    align="center"
                                    key={index}
                                    style={{ width: 'calc(75% / 3)', minWidth: '45px' }}
                                ></TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Mainfeatures.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell>{row.feature === 'Price' ? 'Price/mo' : row.feature}</TableCell>
                                {row.feature === 'Price' ? (
                                    <>
                                        <TableCell align="center">
                                            <Chip
                                                label={row.creator.slice(0, -3)}
                                                color="secondary"
                                                size="small"
                                                style={{ fontWeight: 'bold', fontSize: '0.875rem' }}
                                            />
                                        </TableCell>
                                        <TableCell align="center">
                                            <Chip
                                                label={row.inspirer.slice(0, -3)}
                                                color="secondary"
                                                size="small"
                                                style={{ fontWeight: 'bold', fontSize: '0.875rem' }}
                                            />
                                        </TableCell>
                                        <TableCell align="center">
                                            <Chip
                                                label={row.designer.slice(0, -3)}
                                                color="secondary"
                                                size="small"
                                                style={{ fontWeight: 'bold', fontSize: '0.875rem' }}
                                            />
                                        </TableCell>
                                    </>
                                ) : (
                                    <>
                                        <TableCell align="center">
                                            {row.creator === true ? (
                                                <CheckCircleSharpIcon style={{ color: '#0444AB' }} />
                                            ) : (
                                                row.creator
                                            )}
                                        </TableCell>
                                        <TableCell align="center">
                                            {row.inspirer === true ? (
                                                <CheckCircleSharpIcon style={{ color: '#0444AB' }} />
                                            ) : (
                                                row.inspirer
                                            )}
                                        </TableCell>
                                        <TableCell align="center">
                                            {row.designer === true ? (
                                                <CheckCircleSharpIcon style={{ color: '#0444AB' }} />
                                            ) : (
                                                row.designer
                                            )}
                                        </TableCell>
                                    </>
                                )}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>

            <Box mt={4}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ width: '25%', fontSize: '1.25rem', fontWeight: 'bold' }}>
                                Account & Device
                            </TableCell>
                            {plans.map((plan, index) => (
                                <TableCell
                                    align="center"
                                    key={index}
                                    style={{ width: 'calc(75% / 3)', minWidth: '45px' }}
                                ></TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {AccountFeatures.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell>{row.feature}</TableCell>
                                <TableCell align="center" style={{ width: 'calc(75% / 3)' }}>
                                    {row.creator === true ? (
                                        <CheckCircleSharpIcon style={{ color: '#0444AB' }} />
                                    ) : (
                                        row.creator
                                    )}
                                </TableCell>
                                <TableCell align="center" style={{ width: 'calc(75% / 3)' }}>
                                    {row.inspirer === true ? (
                                        <CheckCircleSharpIcon style={{ color: '#0444AB' }} />
                                    ) : (
                                        row.inspirer
                                    )}
                                </TableCell>
                                <TableCell align="center" style={{ width: 'calc(75% / 3)' }}>
                                    {row.designer === true ? (
                                        <CheckCircleSharpIcon style={{ color: '#0444AB' }} />
                                    ) : (
                                        row.designer
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>

            <Box mt={4}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ width: '25%', fontSize: '1.25rem', fontWeight: 'bold' }}>
                                AI Features
                            </TableCell>
                            {plans.map((plan, index) => (
                                <TableCell
                                    align="center"
                                    key={index}
                                    style={{ width: 'calc(75% / 3)', minWidth: '45px' }}
                                ></TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {AIFeatures.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell style={{ width: '25%' }}>{row.feature}</TableCell>
                                <TableCell align="center" style={{ width: 'calc(75% / 3)' }}>
                                    {row.creator === true ? (
                                        <CheckCircleSharpIcon style={{ color: '#0444AB' }} />
                                    ) : (
                                        row.creator
                                    )}
                                </TableCell>
                                <TableCell align="center" style={{ width: 'calc(75% / 3)' }}>
                                    {row.inspirer === true ? (
                                        <CheckCircleSharpIcon style={{ color: '#0444AB' }} />
                                    ) : (
                                        row.inspirer
                                    )}
                                </TableCell>
                                <TableCell align="center" style={{ width: 'calc(75% / 3)' }}>
                                    {row.designer === true ? (
                                        <CheckCircleSharpIcon style={{ color: '#0444AB' }} />
                                    ) : (
                                        row.designer
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>

            <Box mt={4}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ width: '25%', fontSize: '1.25rem', fontWeight: 'bold' }}>
                                Other Features
                            </TableCell>
                            {plans.map((plan, index) => (
                                <TableCell
                                    align="center"
                                    key={index}
                                    style={{ width: 'calc(75% / 3)', minWidth: '45px' }}
                                ></TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {OtherFeatures.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell style={{ width: '25%' }}>{row.feature}</TableCell>
                                <TableCell align="center" style={{ width: 'calc(75% / 3)' }}>
                                    {row.creator === true ? (
                                        <CheckCircleSharpIcon style={{ color: '#0444AB' }} />
                                    ) : (
                                        row.creator
                                    )}
                                </TableCell>
                                <TableCell align="center" style={{ width: 'calc(75% / 3)' }}>
                                    {row.inspirer === true ? (
                                        <CheckCircleSharpIcon style={{ color: '#0444AB' }} />
                                    ) : (
                                        row.inspirer
                                    )}
                                </TableCell>
                                <TableCell align="center" style={{ width: 'calc(75% / 3)' }}>
                                    {row.designer === true ? (
                                        <CheckCircleSharpIcon style={{ color: '#0444AB' }} />
                                    ) : (
                                        row.designer
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>
        </Container>
    );
};

export default PricingPlans;
