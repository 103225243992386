import { Link, Outlet, useNavigate } from 'react-router-dom';

import { Button } from '@mui/material';

const NavButton = ({ title, customFunc, icon, color, onMouseEnter, onMouseLeave, page }) => (
    //  <Button
    <Button
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onMouseLeave}
        component={Link}
        to={`../${page}`}
        // type='button'
        // onClick={customFunc ? () => customFunc() : () => null}
        style={{ color }}
        className="relative rounded-full hover:text-2xl py-3"
    >
        {/* <span
					style={{ background: dotColor }}
					className='absolute inline-flex rounded-full h-2 w-2 right-2 top-2'
				/> */}
        <span className="font-display text-base font-semibold mx-3">{title === 'Menu' ? '' : title}</span>
        {icon}
    </Button>
);
export default NavButton;
