import {
    Box,
    Button,
    Container,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import { Email, LocationOn } from '@mui/icons-material';
import React, { useState } from 'react';
import config from '../config/config';
import axios from 'axios';

const baseURL = config[process.env.REACT_APP_ENV].REACT_APP_API_URL;

const ContactUs = () => {
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        subject: '',
        message: '',
        source_where: 'website', // Default value for the dropdown
    });

    const [responseMessage, setResponseMessage] = useState('');

    // Function to handle form input changes
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    // Function to handle form submission
    const handleSubmit = async (event) => {
        event.preventDefault();
        try {

            console.log('baseURL',baseURL)


            const response = await axios.post(
                baseURL+'tools/contact_us/submit',
                formData,
            );
            console.log('response',response)
            setResponseMessage('Message sent successfully!');
        } catch (error) {
            setResponseMessage('Failed to send message.');
        }
    };

    return (
        <Container maxWidth="lg" style={{ marginTop: '2rem', marginBottom: '2rem' }}>
            <Box mb={4}>
                <Grid container spacing={8}>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            textAlign: 'center',
                        }}
                    >
                        <Typography variant="h4" component="h1" gutterBottom>
                            We offer 24/7 support
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Contact us today for more information or to request a demo.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <img
                            src="https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/MainPage/D1.jpg"
                            alt="Get in touch"
                            style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
                        />
                    </Grid>
                </Grid>
            </Box>

            <Box mb={4}>
                <Grid container spacing={8}>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            textAlign: 'left',
                        }}
                    >
                        <Typography variant="h4" component="h1" gutterBottom>
                            Get in Touch
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Have any questions or need assistance? We're here to help!
                        </Typography>
                        <Box mb={2} display="flex" alignItems="center">
                            <Email fontSize="small" />
                            <Typography variant="body2" component="span" style={{ marginLeft: '8px' }}>
                                <strong>Technical Support:</strong>{' '}
                                <a href="mailto:support@signinspire.com">support@signinspire.com</a>
                            </Typography>
                            
                        </Box>
                        <Box mb={2} display="flex" alignItems="center">
                            <Email fontSize="small" />
                        
                            <Typography variant="body2" component="span" style={{ marginLeft: '8px' }}>
                                <strong>Sale Enquiry:</strong>{' '}
                                <a href="mailto:sale@signinspire.com">sale@signinspire.com</a>
                            </Typography>
                        </Box>
                        {/* <Box mb={2} display="flex" alignItems="center">
                            <LocationOn fontSize="small" />
                            <Typography variant="body2" component="span" style={{ marginLeft: '8px' }}>
                                <strong>Office:</strong> 2 Portsmouth Ct, Gillman SA 5013 AU
                            </Typography>
                        </Box> 
                        <Button
                            variant="outlined"
                            color="primary"
                            style={{ borderColor: '#0444AB', color: '#0444AB' }}
                            component="a"
                            href="https://www.google.com/maps/search/?api=1&query=2+Portsmouth+Ct,+Gillman+SA+5013+AU"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Get Directions
                        </Button>*/}
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                            <TextField
                                fullWidth
                                required
                                label="First Name"
                                name="first_name"
                                value={formData.first_name}
                                onChange={handleChange}
                                margin="normal"
                            />
                            <TextField
                                fullWidth
                                required
                                label="Last Name"
                                name="last_name"
                                value={formData.last_name}
                                onChange={handleChange}
                                margin="normal"
                            />
                            <TextField
                                fullWidth
                                required
                                label="Email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                margin="normal"
                                type="email"
                            />
                            <TextField
                                fullWidth
                                required
                                label="Subject"
                                name="subject"
                                value={formData.subject}
                                onChange={handleChange}
                                margin="normal"
                            />
                            <TextField
                                fullWidth
                                required
                                label="Message"
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                                margin="normal"
                                multiline
                                rows={4}
                            />
                            <FormControl fullWidth margin="normal" required>
                                <InputLabel id="where-source-label">Where Source</InputLabel>
                                <Select
                                    labelId="where-source-label"
                                    name="source_where"
                                    value={formData.source_where}
                                    onChange={handleChange}
                                    label="Where Source"
                                >
                                    <MenuItem value="website">Website</MenuItem>
                                    <MenuItem value="google">Google</MenuItem>
                                    <MenuItem value="facebook">Facebook</MenuItem>
                                    <MenuItem value="twitter">Twitter</MenuItem>
                                    <MenuItem value="linkedin">LinkedIn</MenuItem>
                                    <MenuItem value="others">Others</MenuItem>
                                </Select>
                            </FormControl>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                style={{ backgroundColor: '#0444AB', color: '#ffffff', marginTop: '16px' }}
                            >
                                Submit
                            </Button>
                        </form>
                        {responseMessage && (
                            <Typography variant="body1" style={{ marginTop: '20px' }}>
                                {responseMessage}
                            </Typography>
                        )}
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
};

export default ContactUs;
