import React, { useState, useEffect } from 'react';
import {
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Box,
    Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { GrDownload } from 'react-icons/gr';
import LazyLoad from 'react-lazyload';

const HeatmapDialog = ({ Heatmap, handleCloseHeatmapDialog, openHeatmapDialog }) => {
    return (
        <Dialog
            open={openHeatmapDialog}
            onClose={handleCloseHeatmapDialog}
            PaperProps={{
                sx: { backgroundColor: 'white', width: '600px', height: 'auto', paddingBottom: '16px' },
            }}
        >
            <DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseHeatmapDialog}
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme.palette.grey[500],
                    })}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ padding: '16px', overflow: 'hidden' }}>
                <Box display="flex" flexDirection="column" alignItems="center">
                    {!Heatmap ? (
                        <Box display="flex" flexDirection="column" alignItems="center">
                            <CircularProgress />
                            <Typography variant="body2" sx={{ mt: 2 }}>
                                Generating...
                            </Typography>
                        </Box>
                    ) : Heatmap.statusCode == 200 ? (
                        <LazyLoad>
                            <img
                                src={Heatmap.message.s3_url}
                                alt="Heatmap"
                                style={{ width: '100%', height: 'auto' }}
                            />
                            <IconButton
                                component="a"
                                href={Heatmap.message.s3_url}
                                download
                                aria-label="download"
                                sx={{ mt: 2 }}
                            ></IconButton>
                        </LazyLoad>
                    ) : (
                        <Typography variant="body2" sx={{ mt: 2 }}>
                            System Busy Please Try Again Later
                        </Typography>
                    )}
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default HeatmapDialog;
