import './App.css';

import {
    AccountManager,
    AccountSettings,
    CampaignManager,
    Dashboard,
    DeviceManager,
    Log,
    Login,
    MediaManager,
    PublishCampaign,
    Register,
    ScheduleCampaign,
    SetUpNewPassword,
    SwitchUser,
    Template,
    VerifyCodePage,
} from './pages';
// import { BrightonGrammar, Epworth, Mitre, Sushi } from './blogs';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import { CampaignDesign, EditCampaign } from './components/dashboard/campaign';
import { ContactUs, Home, Installation, PricingPlans, WebFooter, WebHeader,AdPerformanceTest } from './webPages';
import { Contract, PaymentPage, Subscription, SubscriptionMain } from './components/dashboard/accounting';
import { DashboardNotFound, Notification } from './components/dashboard/utils';
import { Footer, Sidebar } from './components/dashboard';
import { GetMenuList, GetUserInfo, UpdateTokenExpire } from './api/api';
import { IconButton, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import AIPage from './pages/AIPage';
import { Admin } from './components/dashboard/admin';
import { AiOutlineRobot } from 'react-icons/ai';
import { FiSettings } from 'react-icons/fi';
// import { GetMenuList, GetUserInfo } from './api/api';
import { GiArtificialIntelligence } from 'react-icons/gi';
import { MdDashboard } from 'react-icons/md';
// import { Page404 } from './publicPages';
import { SidebarItem } from './components/dashboard/Sidebar';
// import { StripeSubscription } from './components/dashboard/profile/payment';
// import WebBreadcrumbs from './components/website/WebBreadcrumbs';
import { getLastApiCallTime } from './api/TokenManager';
import { links } from './data/dummy';
// import moment from 'moment';
import { useStateContext } from './contexts/ContextProvider';

// import { WebFooter, WebNavbar } from './components/website';

const theme = createTheme({
    typography: {
        fontFamily:
            'Poppins, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Droid Sans, Helvetica Neue, sans-serif',
    },
    palette: {
        primary: {
            main: '#0444AB',
            darker: '#053e85',
        },
        neutral: {
            main: '#64748B',
            contrastText: '#fff',
        },
        gray: {
            light: '#f7f7f7',
            main: '#a9a9a9',
            dark: '#595959',
            contrastText: '#000',
        },
        white: {
            main: '#fff',
            dark: '#f7f7f7',
            contrastText: '#000',
        },
    },
    components: {
        MuiDialog: {
            styleOverrides: {
                paper: {
                    borderRadius: '10px',
                    padding: '20px',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '6px',
                    color: 'white',
                    fontWeight: 600,
                    // letterSpacing: '0.5px',
                    // width: '150px',
                    height: '40px',
                    textTransform: 'capitalize',
                },
                text: {
                    fontWeight: '',
                },
            },
        },
    },
});
const TOKEN_UPDATE_INTERVAL = 10 * 60 * 1000; // 10 mins
const updateToken = async () => {
    // update token
    const data = await UpdateTokenExpire();
};

function App() {
    // const navigate = useNavigate();
    const { currentUser, setCurrentUser, activeMenu, setScreenSize, screenSize } = useStateContext();
    //console.log('App => currentUser:', currentUser);
    // const lastApiCallTime = getLastApiCallTime();
    //  //console.log(
    //     'file: App.js:104 => App => getLastApiCallTime:',
    //     moment(lastApiCallTime).format('MMMM Do YYYY, h:mm:ss a'),
    // );
    const switchUser = JSON.parse(localStorage.getItem('switchUser'));
    // const currentUser = JSON.parse(localStorage.getItem('currentUser'));

    if (process.env.REACT_APP_ENV === 'development') {
        //console.log('This is the development environment');
    } else if (process.env.REACT_APP_ENV === 'production') {
        //console.log('This is the production environment');
    } else {
        //  //console.log(process.env.REACT_APP_ENV);
        //console.log('This is a different environment');
    }

    const pathname = window.location.pathname;
    const [menuList, setMenuList] = useState([]);
    //console.log('App => menuList:', menuList);
    const handleMenuList = async () => {
        const { code, data, msg } = await GetMenuList(switchUser);
        if (code === 0) {
            setMenuList(data?.list);
        }
        // else {
        //     alert(msg);
        // }
    };

    useEffect(() => {
        const handleResize = () => setScreenSize(window.innerWidth);

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Clear local storage if the pathname includes 'login'
    // Otherwise, set the current user if it exists in local storage
    useEffect(() => {
        if (pathname.includes('login')) {
            localStorage.removeItem('currentUser');
            localStorage.removeItem('switchUser');
            localStorage.removeItem('switchUserName');
        } else if (!pathname.includes('switch-user')) {
            const switchedUser = JSON.parse(localStorage.getItem('switchUser'));
            const user = JSON.parse(localStorage.getItem('currentUser'));
            if (user) {
                setCurrentUser(user);
                localStorage.setItem('currentUser', JSON.stringify(user));
            }
        } else {
        }
        handleMenuList();
    }, [pathname]);

    useEffect(() => {
        // 设置定时器检查是否需要更新 token
        // const intervalId = setInterval(checkAndUpdateToken, TOKEN_UPDATE_INTERVAL);
        //  //console.log('useEffect => intervalId:', intervalId);

        // 设置页面点击事件监听器
        const clickListener = () => {
            const lastApiCallTime = getLastApiCallTime();
            //  //console.log('clickListener => lastApiCallTime:', moment(lastApiCallTime).format('MMMM Do YYYY, h:mm:ss a'));
            const currentTime = Date.now();
            //  //console.log('clickListener => currentTime:', moment(currentTime).format('MMMM Do YYYY, h:mm:ss a'));

            //  //console.log('clickListener => currentTime - lastApiCallTime:', currentTime - lastApiCallTime);
            if (currentTime - lastApiCallTime > TOKEN_UPDATE_INTERVAL) {
                //console.log('大于十分钟了，如果用户点击页面更新token');
                updateToken();
                // setLastApiCallTime(currentTime);
            }
        };

        document.addEventListener('click', clickListener);

        return () => {
            // clearInterval(intervalId);
            document.removeEventListener('click', clickListener);
        };
    }, []);

    const [open, setOpen] = useState(false);
    const toggleDrawer = (isOpen) => () => {
        setOpen(isOpen);
    };

    const [userInfo, setUserInfo] = useState();
    //console.log('App => userInfo:', userInfo);
    const handleGetUserInfo = async () => {
        //  //console.log('file: Sidebar.jsx:132 => handleGetStorage => user_id:', switchedUser);
        const { code, data } = await GetUserInfo(currentUser?.user_id);
        if (code === 0) {
            setUserInfo(data);
            localStorage.setItem('userDetails', JSON.stringify(data));
        }
    };

    useEffect(() => {
        handleGetUserInfo();
    }, [currentUser]);

    return (
        <ThemeProvider theme={theme}>
            <div className="w-full">
                <Notification />
                <BrowserRouter basename="/">
                    <div className="w-full flex relative dark:bg-main-dark-bg ">
                        {currentUser && pathname.includes('dashboard') && (
                            <>
                                <div className="fixed right-2 bottom-4" style={{ zIndex: '1000' }}>
                                    <Tooltip
                                        title="AI"
                                        sx={{
                                            bgcolor: 'primary.main',
                                            color: 'primary.contrastText',
                                            p: 2,
                                            '&:hover': {
                                                bgcolor: 'primary.main', // 确保在hover时背景颜色不变
                                                color: 'primary.contrastText', // 确保在hover时文字颜色不变
                                            },
                                        }}
                                    >
                                        <IconButton onClick={toggleDrawer(true)}>
                                            <AiOutlineRobot />
                                        </IconButton>
                                    </Tooltip>
                                </div>

                                <div
                                    className={`${
                                        activeMenu ? 'w-[320px] fixed' : 'w-[80px] fixed'
                                    }  dark:bg-secondary-dark-bg z-[100]`}
                                >
                                    <Sidebar>
                                        {/* {currentUser.user_level === 0 && ( */}
                                        <SidebarItem
                                            icon={<MdDashboard />}
                                            text={`Dashboard`}
                                            path={`/dashboard/home`}
                                            // active={link.path === '/dashboard/schedule'}
                                        />
                                        {/* )} */}
                                        {links
                                            ?.filter((x) =>
                                                menuList?.find(
                                                    (y) => x.name.toLowerCase() === y.menu_name.toLowerCase(),
                                                ),
                                            )
                                            ?.map((link) => (
                                                <SidebarItem
                                                    key={link.name}
                                                    icon={link.icon}
                                                    text={link.name}
                                                    path={link.path}
                                                    // active={link.path === '/dashboard/schedule'}
                                                />
                                            ))}
                                    </Sidebar>
                                </div>
                            </>
                        )}

                        <div
                            className={`dark:bg-main-dark-bg  bg-slate-100 min-h-screen w-full flex flex-col mt-auto
							${currentUser && pathname.includes('dashboard') ? (activeMenu ? 'md:ml-80 ' : 'ml-[80px]') : 'flex-2 '}`}
                        >
                            {/* <div className="mb-auto">
                                <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg  w-full z-[100]">
                                    {currentUser && pathname.includes('dashboard') ? <Navbar /> : ''}
                                </div>
                            </div> */}

                            <div className="mt-0 grow ">
                                <Routes>
                                    <Route path="/" element={<Layout />}>
                                        <Route index element={<Home />} />
                                        <Route path="installation" element={<Installation />} />
                                        <Route path="pricing" element={<PricingPlans />} />
                                        <Route path="contact" element={<ContactUs />} />
                                        <Route path="ad-attention-test" element={<AdPerformanceTest />} />
                                    </Route>
                                </Routes>
                                <Routes>
                                    <Route path="/login" element={<Login />} />
                                    <Route path="/signup" element={<Register />} />
                                    {/* <Route path="/activate" element={<Activate />} /> */}
                                    <Route path="/reset-password" element={<SetUpNewPassword />} />
                                    <Route path="/switch-user" element={<SwitchUser />} />
                                    <Route path="/verify-code" element={<VerifyCodePage />} />
                                    {/* dashboard  */}
                                    {/* <Route path="/" element={<Home />}>
                                        <Route path="/installation" element={<Installation />} />
                                        <Route path="/pricing" element={<PricingPlans />} />{' '}
                                        <Route path="/contact" element={<ContactUs />} />{' '}
                                    </Route> */}

                                    {currentUser && (
                                        <Route>
                                            <Route path="/dashboard/home" element={<Dashboard />} />
                                            <Route path="/dashboard/screen" element={<DeviceManager />} />
                                            <Route path="/dashboard/media" element={<MediaManager />} />
                                            <Route path="/dashboard/playlist" element={<CampaignManager />} />
                                            <Route
                                                path="/dashboard/schedule"
                                                element={<ScheduleCampaign />}
                                            />
                                            <Route
                                                path="/dashboard/publish-campaign"
                                                element={<PublishCampaign />}
                                            />
                                            {currentUser.user_level === 0 && (
                                                <Route
                                                    path="/dashboard/account-manager"
                                                    element={<AccountManager />}
                                                />
                                            )}
                                            <Route
                                                path="/dashboard/playlist/edit-campaign"
                                                element={<EditCampaign />}
                                            />
                                            <Route
                                                path="/dashboard/playlist/template"
                                                element={<Template />}
                                            />

                                            <Route
                                                path="/dashboard/playlist/campaign-design"
                                                element={<CampaignDesign />}
                                            />
                                            <Route
                                                path="/dashboard/account-settings"
                                                element={<AccountSettings />}
                                            />

                                            {/* <Route path="/dashboard/contract" element={<Contract />} /> */}
                                            <Route path="/dashboard/payment" element={<SubscriptionMain />} />
                                            <Route path="/dashboard/log" element={<Log />} />
                                            <Route path="/dashboard/admin" element={<Admin />} />
                                            <Route
                                                path="/dashboard/subscription"
                                                element={<Subscription />}
                                            />
                                            <Route path="/dashboard/*" element={<DashboardNotFound />} />
                                            {/* <Route path="/*" element={<Page404 />} /> */}
                                        </Route>
                                    )}
                                </Routes>
                            </div>

                            {currentUser &&
                                (pathname.includes('dashboard') ||
                                    pathname.includes('login') ||
                                    pathname.includes('signup') ||
                                    pathname.includes('activate')) && (
                                    <Footer />
                                    // ) : (
                                    //     !(
                                    //         pathname.includes('dashboard') ||
                                    //         pathname.includes('login') ||
                                    //         pathname.includes('signup') ||
                                    //         pathname.includes('activate')
                                    //     ) && <WebFooter />
                                )}
                        </div>
                    </div>
                    {/* {currentUser && (
                        <TokenChecker
                            token={token}
                            expirationTime={expirationTime}
                            onTokenExpired={handleTokenExpired}
                        />
                    )} */}
                </BrowserRouter>
                <AIPage toggleDrawer={toggleDrawer} open={open} />
            </div>
        </ThemeProvider>
    );
}

const Layout = () => {
    return (
        <>
            <WebHeader />
            <Outlet />
            <WebFooter />
        </>
    );
};
export default App;
