import * as Yup from 'yup';

import {
    Box,
    Button,
    Card,
    Checkbox,
    Container,
    FormControlLabel,
    FormGroup,
    IconButton,
    InputAdornment,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material';
import { ChangePassword, GetUserInfo, RequestLogin, RetrievePassword, SendVerifyCode } from '../api/api';
import { Field, Formik, useFormik } from 'formik';
import { Iconify, LoginComponent, RedditTextField } from '../components/dashboard';
import React, { useEffect, useState } from 'react';

import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useStateContext } from '../contexts/ContextProvider';

const Login = () => {
    const navigate = useNavigate();
    const { setCurrentUser } = useStateContext();
    const [alert, setAlert] = useState(false);
    const [alertText, setAlertText] = useState('');
    const [user, setUser] = useState();
    const [resetPassword, setResetPassword] = useState(false);
    const remembered = JSON.parse(localStorage.getItem('rememberedCredentials'));
    //console.log('file: Login.jsx:35 => Login => remembered:', remembered);

    const formik = useFormik({
        initialValues: {
            username: remembered ? remembered.username : '',
            password: remembered ? remembered.password : '',
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            username: Yup.string().max(255).required('Username is required'),
            password: Yup.string().max(255).required('Password is required'),
        }),
        onSubmit: (values) => {
            const { username, password } = values;
            //console.log(username, password);
            handleLogin(username, password);
        },
    });

    const recoveryFormik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().max(255).required('Email is required'),
        }),
        onSubmit: (values) => {
            const { email } = values;
            //console.log('Login => email:', email);
            handleSendPassword(email);
        },
    });

    const passwordFormik = useFormik({
        initialValues: {
            tempPassword: '',
            newPassword: '',
            confirmNewPassword: '',
        },
        validationSchema: Yup.object({
            tempPassword: Yup.string().max(255).required('Temporary password is required'),
            newPassword: Yup.string().max(255).required('New password is required'),
            confirmNewPassword: Yup.string().max(255).required('Confirm new password is required'),
        }),
        onSubmit: (values) => {
            const { tempPassword, newPassword, confirmNewPassword } = values;
            //console.log('confirmNewPassword:', confirmNewPassword);
            //console.log('newPassword:', newPassword);
            //console.log('tempPassword:', tempPassword);
            handleChangePassword(tempPassword, newPassword, confirmNewPassword);
        },
    });
    const handleGetProfileDetail = async (id, currentUser) => {
        const { data, code } = await GetUserInfo(id);
        //console.log('handleGetProfileDetail => data:', data);
        if (code === 0) {
            // setProfileDetail(data);
            if (data.double_login_validate === 1) {
                const codeData = await SendVerifyCode('double_login_validate');
                //console.log('handleGetProfileDetail => data:', codeData);
                if (codeData.code === 0) {
                    navigate('/verify-code');
                }
            } else {
                if (currentUser.user_level === 0) {
                    navigate('/switch-user');
                } else {
                    setCurrentUser(currentUser);
                    navigate('/dashboard/home');
                }
            }
        }
    };

    const handleLogin = async (username, password) => {
        //console.log('file: Login.jsx:86 => handleLogin => password:', password);
        //console.log('file: Login.jsx:86 => handleLogin => username:', username);
        const { code, data, msg } = await RequestLogin(username, password);
        //console.log('request:', code);
        if (code === 0) {
            //console.log('data:', data);
            localStorage.setItem('currentUser', JSON.stringify(data));
            localStorage.setItem('token', JSON.stringify(data.token));
            if (data.force_reset === 0) {
                handleGetProfileDetail(data.user_id, data);
                // if (data.user_level === 0) {
                //     navigate('/switch-user');
                // } else {
                //     setCurrentUser(data);
                //     navigate('/dashboard/schedule');
                // }
            }
            if (data.force_reset === 1) {
                //console.log('force_reset:');
                setForgetPassword(!forgetPassword);
                setIsSignIn(false);
                setResetPassword(true);
                setAlertText('');
                setUser(data);
            }
        } else {
            setAlert(true);
            setAlertText(msg);
        }
    };

    const [isSignIn, setIsSignIn] = useState(true);

    /**
     *
     * Navigate to register page
     *
     */
    const handleRegister = () => {
        navigate('/signup');
    };

    /**
     *
     * Handle  retrieve password
     * @param {*} email
     *
     *
     */
    const [forgetPassword, setForgetPassword] = useState(false);
    const [sent, setSent] = useState(false);

    const handleForgetPassword = () => {
        //console.log('forgetPassword');
        setForgetPassword(true);
        setIsSignIn(false);
    };

    // Return to login page from retrieve password page
    const handleReturnToLogin = () => {
        setIsSignIn(!isSignIn);
        setForgetPassword(!forgetPassword);
        setSent(false);
        navigate('/login', { replace: true });
    };

    // Handle send password to user's email address
    // const [sentLink, setSentLink] = useState(false)
    const handleSendPassword = async (email) => {
        //console.log('Send Password');
        const data = await RetrievePassword(email);
        //console.log('handleSendPassword => data:', data);
        setSent(true);
        // if (data?.code === 0) {
        //     //  //console.log('Temporary password is sent to your email!');
        //     // handleReturnToLogin();
        // } else {
        //      //console.log(data?.msg);
        // }
    };

    const handleChangePassword = async (current_password, new_password, confirm_password) => {
        const { code } = await ChangePassword(current_password, new_password, confirm_password);
        if (code === 0) {
            if (user?.user_level === 0) {
                navigate('/switch-user');
            } else {
                setCurrentUser(user);
                navigate('/dashboard');
            }
        } else {
            alert('Change password successfully!!');
        }
    };
    const [showPassword, setShowPassword] = React.useState(false);
    const [isRemember, setIsRemember] = React.useState(false); // Add remember me state
    const handleChange = (event) => {
        setIsRemember(event.target.checked);
        if (event.target.checked) {
            localStorage.setItem(
                'rememberedCredentials',
                JSON.stringify({
                    username: formik.values.username,
                    password: formik.values.password,
                }),
            );
        }
    };
    return (
        <div className="h-screen w-full md:flex">
            <LoginComponent />

            <div className="w-full md:w-1/2  h-full  z-50">
                <Box
                    component="main"
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        flexGrow: 1,
                        minHeight: '100%',
                    }}
                >
                    <Container maxWidth="sm">
                        <div className="p-16 ">
                            {isSignIn && !resetPassword && (
                                <form onSubmit={formik.handleSubmit}>
                                    <Stack spacing={3}>
                                        <Typography
                                            className="text-center "
                                            sx={{
                                                fontFamily: 'Inter',
                                                fontWeight: 800,
                                                fontSize: '48px',
                                            }}
                                        >
                                            Log in{' '}
                                        </Typography>
                                        <Typography className="text-center " variant="body1">
                                            Welcome back! Please enter your details{' '}
                                        </Typography>
                                        <RedditTextField
                                            error={Boolean(formik.touched.username && formik.errors.username)}
                                            fullWidth
                                            helperText={formik.touched.username && formik.errors.username}
                                            label="Username/Email"
                                            autoComplete="username"
                                            name="username"
                                            variant="filled"
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            value={formik.values.username}
                                        />
                                        <RedditTextField
                                            error={Boolean(formik.touched.password && formik.errors.password)}
                                            fullWidth
                                            helperText={formik.touched.password && formik.errors.password}
                                            label="Password"
                                            name="password"
                                            autoComplete="password"
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            type={showPassword ? 'text' : 'password'}
                                            value={formik.values.password}
                                            variant="filled"
                                            InputProps={{
                                                disableUnderline: true,
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={() => setShowPassword(!showPassword)}
                                                            edge="end"
                                                        >
                                                            <Iconify
                                                                icon={
                                                                    showPassword
                                                                        ? 'eva:eye-fill'
                                                                        : 'eva:eye-off-fill'
                                                                }
                                                            />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />

                                        <div>
                                            {isSignIn && !forgetPassword ? (
                                                <div className="flex justify-between space-x-auto items-center">
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={isRemember}
                                                                onChange={handleChange}
                                                                name="remember"
                                                            />
                                                        }
                                                        label="Remember me"
                                                    />
                                                    <Typography
                                                        className="hover:underline text-sky-400 cursor-pointer font-medium"
                                                        variant="subtitle1"
                                                        onClick={handleForgetPassword}
                                                    >
                                                        Forgot Password?
                                                    </Typography>
                                                </div>
                                            ) : null}
                                        </div>
                                        <Box sx={{ py: 1 }}>
                                            {alert && (
                                                <Typography
                                                    className="text-red-500  text-center"
                                                    sx={{
                                                        fontSize: '12px',
                                                        marginBottom: '10px',
                                                    }}
                                                >
                                                    {alertText}
                                                </Typography>
                                            )}
                                            <div className="flex w-full justify-center">
                                                <Button
                                                    color="primary"
                                                    // disabled={formik.isSubmitting}
                                                    fullWidth
                                                    size="large"
                                                    type="submit"
                                                    variant="contained"
                                                    style={{
                                                        color: '#ffffff',
                                                        fontSize: '18px',
                                                        fontWeight: 600,
                                                        padding: '24px 32px',
                                                    }}
                                                >
                                                    Sign In
                                                </Button>
                                            </div>
                                        </Box>
                                    </Stack>
                                </form>
                            )}
                            {resetPassword && (
                                <form onSubmit={passwordFormik.handleSubmit}>
                                    <Stack spacing={3}>
                                        <Typography
                                            className="text-gray-500 text-14 text-center"
                                            sx={{ fontWeight: 600 }}
                                        >
                                            Reset password
                                        </Typography>
                                        <RedditTextField
                                            error={Boolean(
                                                passwordFormik.touched.tempPassword &&
                                                    passwordFormik.errors.tempPassword,
                                            )}
                                            fullWidth
                                            required
                                            helperText={
                                                passwordFormik.touched.tempPassword &&
                                                passwordFormik.errors.tempPassword
                                            }
                                            label="Temporary Password"
                                            name="tempPassword"
                                            variant="filled"
                                            onBlur={passwordFormik.handleBlur}
                                            onChange={passwordFormik.handleChange}
                                            type="password"
                                            value={passwordFormik.values.tempPassword}
                                        />
                                        <RedditTextField
                                            error={Boolean(
                                                passwordFormik.touched.newPassword &&
                                                    passwordFormik.errors.newPassword,
                                            )}
                                            fullWidth
                                            helperText={
                                                passwordFormik.touched.newPassword &&
                                                passwordFormik.errors.newPassword
                                            }
                                            label="New password"
                                            name="newPassword"
                                            variant="filled"
                                            onBlur={passwordFormik.handleBlur}
                                            onChange={passwordFormik.handleChange}
                                            type="password"
                                            value={passwordFormik.values.newPassword}
                                        />
                                        <RedditTextField
                                            error={Boolean(
                                                passwordFormik.touched.confirmNewPassword &&
                                                    passwordFormik.errors.confirmNewPassword,
                                            )}
                                            fullWidth
                                            helperText={
                                                passwordFormik.touched.confirmNewPassword &&
                                                passwordFormik.errors.confirmNewPassword
                                            }
                                            label="Confirm new password"
                                            name="confirmNewPassword"
                                            onBlur={passwordFormik.handleBlur}
                                            onChange={passwordFormik.handleChange}
                                            type="password"
                                            value={passwordFormik.values.confirmNewPassword}
                                        />

                                        <Box sx={{ py: 1 }}>
                                            {alert && (
                                                <Typography
                                                    className="text-red-500  text-center"
                                                    sx={{
                                                        fontSize: '12px',
                                                        marginBottom: '10px',
                                                    }}
                                                >
                                                    {alertText}
                                                </Typography>
                                            )}
                                            <Button
                                                color="primary"
                                                fullWidth
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                                style={{
                                                    color: '#ffffff',
                                                    fontSize: '18px',
                                                    fontWeight: 600,
                                                    padding: '24px 32px',
                                                }}
                                            >
                                                Change password
                                            </Button>
                                        </Box>
                                    </Stack>
                                </form>
                            )}

                            {forgetPassword && !resetPassword && (
                                <form onSubmit={recoveryFormik.handleSubmit}>
                                    <Stack spacing={3}>
                                        <Typography
                                            className="text-center "
                                            style={{
                                                fontFamily: 'Inter',
                                                fontWeight: 800,
                                                fontSize: '48px',
                                            }}
                                        >
                                            Can't log in?
                                        </Typography>
                                        <Typography variant="body1">
                                            {' '}
                                            We'll send a temporary password to
                                        </Typography>
                                        <RedditTextField
                                            error={Boolean(
                                                recoveryFormik.touched.email && recoveryFormik.errors.email,
                                            )}
                                            fullWidth
                                            required
                                            helperText={
                                                recoveryFormik.touched.email && recoveryFormik.errors.email
                                            }
                                            label={`email address`}
                                            name="email"
                                            type="email"
                                            variant="filled"
                                            onBlur={recoveryFormik.handleBlur}
                                            onChange={recoveryFormik.handleChange}
                                            value={recoveryFormik.values.email}
                                        />
                                        <div className="flex w-full justify-center">
                                            {!sent ? (
                                                <Box sx={{ py: 1 }}>
                                                    <Button
                                                        color="primary"
                                                        fullWidth
                                                        size="large"
                                                        type="submit"
                                                        variant="contained"
                                                        // disabled={sent}
                                                        style={{
                                                            color: '#ffffff',
                                                            fontSize: '18px',
                                                            padding: '24px 32px',
                                                        }}
                                                        // onClick={handleSendPassword}
                                                    >
                                                        Send temporary password
                                                    </Button>
                                                </Box>
                                            ) : (
                                                <div className="border-blue-400 border-solid border-2 bg-blue-100 w-full p-2 mb-2">
                                                    <p className="text-sm font-bold">
                                                        If you have an account with us, you will receive an
                                                        email with password reset instructions soon.
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    </Stack>
                                </form>
                            )}
                            <div className="flex w-full justify-center">
                                {isSignIn && !forgetPassword ? (
                                    <ul className="whitespace-nowrap space-x-3">
                                        <li className="inline-block">
                                            <Typography variant="body2">Don't have an account?</Typography>
                                        </li>
                                        <li className="inline-block">
                                            <Typography
                                                className="hover:underline cursor-pointer"
                                                variant="subtitle2"
                                                onClick={handleRegister}
                                                style={{ fontWeight: 600 }}
                                            >
                                                Sign up
                                            </Typography>
                                        </li>
                                    </ul>
                                ) : (
                                    !forgetPassword && (
                                        <ul className="whitespace-nowrap space-x-3">
                                            <li className="inline-block">
                                                <Typography variant="body2">
                                                    Already have a Sign Inspire account?
                                                </Typography>
                                            </li>
                                            <li className="inline-block">
                                                <Typography
                                                    className="hover:underline  cursor-pointer font-medium"
                                                    variant="subtitle2"
                                                    onClick={handleReturnToLogin}
                                                    style={{ fontWeight: 600 }}
                                                >
                                                    Log in
                                                </Typography>
                                            </li>
                                        </ul>
                                    )
                                )}
                                {forgetPassword && !resetPassword && (
                                    <ul className="whitespace-nowrap space-x-1">
                                        <li className="inline-block">
                                            <Typography variant="body2">Return to</Typography>
                                        </li>
                                        <li className="inline-block">
                                            <Typography
                                                className="hover:underline  cursor-pointer font-medium"
                                                variant="subtitle2"
                                                onClick={handleReturnToLogin}
                                                style={{ fontWeight: 600 }}
                                            >
                                                Log in
                                            </Typography>
                                        </li>
                                    </ul>
                                )}
                            </div>
                        </div>
                    </Container>
                </Box>
            </div>
        </div>
    );
};

export default Login;
