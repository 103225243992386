import { Box, Container, Grid, IconButton, Link, Typography } from '@mui/material';
import { Facebook, Instagram, LinkedIn, Twitter } from '@mui/icons-material';

import React from 'react';

import config from '../config/config';

//const RDS_URL = config[process.env.REACT_APP_ENV].REACT_APP_RDS_API_URL;
//const redirect_base_url = config[process.env.REACT_APP_ENV].REACT_APP_MAIN_PAGE_URL_HEAD;


const installation_url = "/installation"
const pricing_url = "/pricing"
const contact_url = "/contact"

const WebFooter = () => {
    return (
        <Box sx={{ bgcolor: '#f8f8f8', py: 6 }}>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={4}>
                        <Typography variant="h6" gutterBottom>
                            AI Leading Digital Signage Platform
                        </Typography>
                        <IconButton href='/' target="_blank" rel="noopener">
                            <img
                                src="https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/MainPage/icon_app.png"
                                alt="App Icon"
                                style={{ width: '100px', height: '100px' }}
                            />
                        </IconButton>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography variant="h6" gutterBottom>
                            Quick Links
                        </Typography>
                        <Box>
                            <Link href='/' variant="body2" color="textPrimary" display="block" gutterBottom>
                                Home
                            </Link>
                            <Link href={installation_url} variant="body2" color="textPrimary" display="block" gutterBottom>
                                Installation
                            </Link>
                            <Link href={pricing_url} variant="body2" color="textPrimary" display="block" gutterBottom>
                                Pricing
                            </Link>
                            <Link href={contact_url} variant="body2" color="textPrimary" display="block" gutterBottom>
                                Contact
                            </Link>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography variant="h6" gutterBottom>
                            Follow Us
                        </Typography>
                        <Box>
                            <IconButton aria-label="Facebook" href="https://facebook.com">
                                <Facebook />
                            </IconButton>
                            <IconButton aria-label="Twitter" href="https://twitter.com">
                                <Twitter />
                            </IconButton>
                            <IconButton aria-label="LinkedIn" href="https://linkedin.com">
                                <LinkedIn />
                            </IconButton>
                            <IconButton aria-label="Instagram" href="https://instagram.com">
                                <Instagram />
                            </IconButton>
                        </Box>
                    </Grid>
                </Grid>
                <Box mt={4} textAlign="center">
                    <Typography variant="body2" color="textSecondary">
                        &copy; {new Date().getFullYear()} Sign Inspire All rights reserved.
                    </Typography>
                </Box>
            </Container>
        </Box>
    );
};

export default WebFooter;
