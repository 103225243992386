import {
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Divider,
    Typography,
    Box,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import WarningIcon from '@mui/icons-material/Warning';
import PropTypes from 'prop-types';
import React from 'react';

const ImageDescriptionDialog = ({
    ImageDescription,
    handleCloseImageDescriptionDialog,
    openImageDescriptionDialog,
}) => {
    return (
        <Dialog
            open={openImageDescriptionDialog}
            PaperProps={{
                sx: { backgroundColor: 'white', width: '600px', height: 'auto', paddingBottom: '16px' },
            }}
        >
            <DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseImageDescriptionDialog}
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme.palette.grey[500],
                    })}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ padding: '16px', overflow: 'hidden' }}>
                <Box display="flex" flexDirection="column" alignItems="center">
                    {!ImageDescription ? (
                        <Box display="flex" flexDirection="column" alignItems="center">
                            <CircularProgress />
                            <Typography variant="body2" sx={{ mt: 2 }}>
                                Generating...
                            </Typography>
                        </Box>
                    ) : ImageDescription.Strengths && ImageDescription['Areas for Improvement'] ? (
                        <>
                            {/* Strengths Section */}
                            <Box display="flex" alignItems="center" sx={{ mb: 1 }}>
                                <ThumbUpIcon color="primary" sx={{ mr: 1 }} />
                                <Typography variant="h6" component="h3">
                                    Strengths
                                </Typography>
                            </Box>
                            <ul className="list-disc pl-5">
                                {ImageDescription.Strengths.map((strength, index) => (
                                    <li key={index}>{strength}</li>
                                ))}
                            </ul>

                            <Divider sx={{ my: 2, width: '100%' }} />

                            {/* Areas for Improvement Section */}
                            <Box display="flex" alignItems="center" sx={{ mb: 1 }}>
                                <WarningIcon color="error" sx={{ mr: 1 }} />
                                <Typography variant="h6" component="h3">
                                    Areas for Improvement
                                </Typography>
                            </Box>
                            <ul className="list-disc pl-5">
                                {ImageDescription['Areas for Improvement'].map((area, index) => (
                                    <li key={index}>{area}</li>
                                ))}
                            </ul>

                            <Divider sx={{ my: 2, width: '100%' }} />

                            {/* Overall Effectiveness Section */}
                            <Typography variant="h6" component="h3" sx={{ mb: 1 }}>
                                Overall Effectiveness
                            </Typography>
                            <Typography variant="body1" sx={{ color: 'text.primary' }}>
                                {ImageDescription['Overall Effectiveness']}
                            </Typography>
                        </>
                    ) : (
                        <Typography variant="body2" sx={{ mt: 2 }}>
                            System Busdy Please Try Again Later
                        </Typography>
                    )}
                </Box>
            </DialogContent>
        </Dialog>
    );
};

ImageDescriptionDialog.propTypes = {
    ImageDescription: PropTypes.shape({
        Strengths: PropTypes.arrayOf(PropTypes.string),
        'Areas for Improvement': PropTypes.arrayOf(PropTypes.string),
        'Overall Effectiveness': PropTypes.string,
    }),
    handleCloseImageDescriptionDialog: PropTypes.func.isRequired,
    openImageDescriptionDialog: PropTypes.bool.isRequired,
};

export default ImageDescriptionDialog;
